<template>
  <div>
    <el-table
      :data="alarmList"
      stripe
      style="width: 100%"
      @row-click="rowClickHandle"
      height="100%"
    >
    <template slot="empty">没有警情需要处理</template>
      <el-table-column
        prop="name"
        label="设备"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="typeStr"
        label="警情类型"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="value"
        label="报警值"
        width="70"
      >
      </el-table-column>
      <el-table-column
        prop="time"
        label="报警时间"
      >
      </el-table-column>
      <el-table-column
        prop="serial"
        v-if="1==0"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data(){
    return{
    }
  },
  props:{
    alarmList:Array
  },
  methods:{
    rowClickHandle(row){
      this.$emit("oneClick",row);
    }
  }
};
</script>

<style>
</style>