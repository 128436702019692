<template>
  <bm-info-window
    :position="position"
    :title="data.name"
    :show="show"
    @close="infoWindowClose"
    @open="infoWindowOpen"
  >
    <div class="info-rrapper">
      <div class="row_1">
        <i>警情类型：</i><i>{{data.typeStr}}</i><br>
        <i> 警情值：</i><i style="color:red">{{data.value}}</i>
      </div>
      <div class="row_2">
        <div><i>实时状态：</i></div>
        <div><i>电流：</i><i>{{deviceItem.ai}}</i></div>
        <div><i>电压：</i><i>{{deviceItem.au}}</i></div>
      </div>
      <div class="row_3">
        <el-button size="mini" @click="handleAlarm">处理</el-button>
      </div>
    </div>
  </bm-info-window>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    position: Object,
    show: Boolean,
    data: Object,
    device: Array,
  },
  computed: {
    deviceItem: function () {
      var length = this.$props.device.length;
      for (var i = 0; i < length; i++) {
        if (this.$props.device[i].id == this.$props.data.id) {
          return this.$props.device[i];
        }
      }
      return {};
    },
  },
  methods: {
    infoWindowClose() {
      this.$emit("close");
    },
    infoWindowOpen() {
      console.log("open");
    },
    handleAlarm(){
      this.$emit("handelClick",this.$props.data.serial);
      this.infoWindowClose();
    }
  },
};
</script>

<style>
.info-rrapper > .row_1 {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.info-rrapper > .row_2{
  margin-bottom: 5px;
}

.info-rrapper > .row_3{
 text-align: right;
}
</style>