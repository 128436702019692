<template>
  <div class="container">
    <div class="map-wrapper">
      <baidu-map
        class="map"
        center="唐山"
        @ready="handler"
        :scroll-wheel-zoom="true"
      >
        <alarm-overlay
          :position="alarmOverlayImf.position"
          :data="alarmOverlayImf.data"
          :device="device"
          :show="alarmOverlayImf.show"
          @close="handleOverlayClose"
          @handelClick="handleAlarm"
        ></alarm-overlay>
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
        <bm-geolocation
          anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
          :showAddressBar="true"
          :autoLocation="true"
        ></bm-geolocation>
      </baidu-map>
    </div>
    <div class="alarm-table">
      <alarm
        :alarmList="alarmList"
        @oneClick="oneClick_handle"
      ></alarm>
    </div>
  </div>
</template>

<script>
import Alarm from "../components/Alarm.vue";
import AlarmOverlay from "../components/AlarmOverlay.vue";
import axios from 'axios';
import qs from 'qs'
var apiServer = "http://39.105.155.249:3000"
export default {
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 15,
      map: {},
      BMap: {},
      alarmOverlayImf: {
        show: false,
        position: {
          lng: 116.404,
          lat: 39.915,
        },
        data: {},
      },
    };
  },
  props: {
    alarmList: Array,
    device: Array,
  },
  components: {
    Alarm,
    AlarmOverlay,
  },
  methods: {
    handler({ BMap, map }) {
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(
        (r) => {
          var mk = new BMap.Marker(r.point);
          this.map = map;
          this.BMap = BMap;
          map.addOverlay(mk);
          map.setCenter(r.point);
        },
        { enableHighAccuracy: true }
      );
    },
    setMapCenter(lng, lat) {
      if (Object.keys(this.map) != 0) {
        this.map.clearOverlays();
        var point = new this.BMap.Point(lng, lat);
        var zoom = this.map.getZoom();
        this.map.centerAndZoom(point, zoom);
        var mk = new this.BMap.Marker(point);
        this.map.addOverlay(mk);
      }
    },
    showAlarmOverlay(lng, lat) {
      this.alarmOverlayImf.position.lng = lng;
      this.alarmOverlayImf.position.lat = lat;
      this.alarmOverlayImf.show = true;
    },
    oneClick_handle(row) {
      var api = "http://39.105.155.249:3000/getPoint/?id=" + row.id;
      let that = this;
      this.alarmOverlayImf.data = row;
      this.alarmOverlayImf.show = false;
      axios
        .post(api)
        .then((result) => {
          that.setMapCenter(result.data.lng, result.data.lat);
          that.showAlarmOverlay(result.data.lng, result.data.lat);
        })
        .catch((e) => {
          console.log(e);
        });
      this.$emit("oneClicked");
    },
    handleOverlayClose() {
      this.alarmOverlayImf.show = false;
    },
    handleAlarm(serial) {
      let url = apiServer + "/handleAlarm";
      let data = {};
      data.account = localStorage.getItem("account");
      data.serial = serial;
      axios
        .post(url, qs.stringify(data))
        .then((r) => {
          if (r.data.code == 0) {
            this.$emit("handelAlarmSucced", serial);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  margin: 0 auto;
  position: relative;
}

.container::after {
  content: "";
  clear: both;
  display: table;
}

.map-wrapper {
  float: left;
  width: 65%;
}

.alarm-table {
  float: right;
  width: 35%;
}

.alarm-table /deep/ .el-table__body-wrapper {
  height: calc(100vh - 120px);
}

.alarm-table /deep/ .el-table__header-wrapper {
  border-top: solid 1px #e6e6e6;
}
.map {
  height: calc(100vh - 70px);
}
</style>
